import { Shop } from '@b4online/api-common'
import { Autocomplete, InputAdornment, Link, Popper, PopperProps, TextField, TextFieldProps, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { useRouter } from 'next/router'
import React from 'react'

import { transformUrlName } from '../../utils/transformUrlName'
import { Icon } from '../Icon/Icon'
import { SearchNotFound } from '../SearchNotFound/SearchNotFound'

type ArrayType<T> = T extends Array<infer E> ? E : never
type Product = ArrayType<Shop.GetProductsResponse['entries']>

interface ShopProductSearchProps {
  products: Shop.GetProductsResponse['entries']
}

export const ShopProductSearch: React.FC<ShopProductSearchProps> = ({ products }) => {
  const [searchQuery, setSearchQuery] = React.useState('')
  const [searchResults, setSearchResults] = React.useState<Product[]>([])
  const router = useRouter()

  const handleChangeSearch = (value: string) => {
    setSearchQuery(value)
    if (value) {
      const filtered = products.filter(product => {
        const name = product.name.default ?? product.name.de
        const parts = value.toLowerCase().split(/[ _\-.]/)
        return parts.every(part => name.toLowerCase().includes(part))
      })
      setSearchResults(filtered)
    }
  }

  const handleClick = async (product: Product) => {
    await router.push(`/shop/${transformUrlName(product.category.default ?? product.category.de ?? '-')}/${transformUrlName(product.name.default ?? product.name.de)}`)
  }

  const handleKeyUp = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && searchResults.length > 0) {
      await handleClick(searchResults[0])
    }
  }

  return (
    <Autocomplete
      size={'small'}
      autoHighlight
      popupIcon={null}
      PopperComponent={PopperStyle}
      options={searchResults}
      onInputChange={(event, value) => handleChangeSearch(value)}
      getOptionLabel={(product: Product) => product.name.default ?? product.name.de}
      noOptionsText={<SearchNotFound searchQuery={searchQuery} />}
      isOptionEqualToValue={(option, value) => option._id === value._id}
      renderInput={params => (
        <InputStyle
          {...params}
          stretchStart={200}
          placeholder={'Produkt suchen...'}
          onKeyUp={handleKeyUp}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position={'start'}>
                <Icon icon={'eva:search-fill'} sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />
      )}
      renderOption={(props, product, { inputValue }) => {
        const name = product.name.default ?? product.name.de
        const cover = product.images[0]
        const matches = match(name, inputValue)
        const parts = parse(name, matches)

        return (
          <li {...props}>
            <Image alt={cover} src={cover} sx={{ width: 48, height: 48, borderRadius: 1, flexShrink: 0, mr: 1.5 }} />
            <Link underline={'none'} onClick={() => handleClick(product)}>
              {parts.map((part, index) => (
                <Typography key={index} component={'span'} variant={'subtitle2'} color={part.highlight ? 'primary' : 'textPrimary'}>
                  {part.text}
                </Typography>
              ))}
            </Link>
          </li>
        )
      }}
    />
  )
}

const PopperStyle = styled((props: PopperProps) => <Popper placement={'bottom-start'} {...props} />)({
  width: '280px !important',
})

const Image = styled('img')(() => ({
  objectFit: 'contain',
}))

const InputStyle = styled(TextField, {
  shouldForwardProp: prop => prop !== 'stretchStart',
})<TextFieldProps & { stretchStart?: number }>(({ stretchStart, theme }) => ({
  '& .MuiOutlinedInput-root': {
    transition: theme.transitions.create(['box-shadow', 'width'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': {
      boxShadow: theme.customShadows.z12,
    },
    ...(stretchStart && {
      width: stretchStart,
      '&.Mui-focused': {
        boxShadow: theme.customShadows.z12,
        [theme.breakpoints.up('sm')]: {
          width: stretchStart + 60,
        },
      },
    }),
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}))
