import { Warehouse } from '@b4online/api-common'
import { Box, Button, darken, Divider, IconButton, MenuItem, Select, Stack, Typography } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { isEqual } from 'lodash'
import React from 'react'

import { ShopBasket } from '../../hooks/useShopBasket'
import { Icon } from '../Icon/Icon'
import { Label } from '../Label/Label'

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
  [theme.breakpoints.up(1368)]: {
    padding: theme.spacing(5, 8),
  },
}))

type Product = Warehouse.ProductWithVariants<Omit<Warehouse.Product, 'shipping'> & { shipping: number }>

type Props = {
  product: Product
  basket: ShopBasket
  updateBasket: React.Dispatch<React.SetStateAction<ShopBasket>>
}

const color = '#dfa649'

export function ProductDetailsSummary({ product: product, basket, updateBasket, ...other }: Props) {
  const [selected, setSelected] = React.useState<Product>(product.variants?.[0] ?? product)
  const theme = useTheme()

  const status = selected.price.comparison !== undefined && selected.price.comparison > selected.price.sell ? 'sale' : undefined

  const currentQuantity = basket.entries.find(entry => entry.id === selected._id)?.quantity ?? 0
  const maxQuantity = selected.oversell ? Number.MAX_VALUE : selected.stock ?? Number.MAX_VALUE
  const available = maxQuantity === Number.MAX_VALUE ? Number.MAX_VALUE : maxQuantity - currentQuantity

  const [quantity, setQuantity] = React.useState(selected.stock === 0 ? 0 : 1)
  React.useEffect(() => {
    setQuantity(selected.stock === 0 ? 0 : 1)
  }, [selected])

  const handleAdd = () => {
    if (currentQuantity + quantity > maxQuantity) return
    updateBasket(previous => {
      if (previous.entries.findIndex(item => item.id === selected._id) !== -1) {
        return {
          entries: previous.entries.map(item => {
            if (item.id === selected._id) {
              return {
                ...item,
                quantity: item.quantity + quantity,
              }
            }
            return item
          }),
        }
      } else {
        return {
          entries: [
            ...previous.entries,
            {
              id: selected._id,
              quantity: quantity,
            },
          ],
        }
      }
    })
  }

  return (
    <RootStyle {...other}>
      <Label
        variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
        color={selected.stock === undefined || selected.stock > 0 ? 'success' : 'error'}
        sx={{ textTransform: 'uppercase' }}>
        {selected.stock === undefined || selected.stock > 0 ? 'Auf Lager' : 'Ausverkauft'}
      </Label>
      <Typography
        variant={'overline'}
        sx={{
          mt: 2,
          mb: 1,
          display: 'block',
          color: status === 'sale' ? 'error.main' : 'info.main',
        }}>
        {status}
      </Typography>
      <Typography variant={'h5'} paragraph>
        {selected.name.default}
      </Typography>

      <Typography variant={'h4'}>
        {selected.price.comparison !== undefined && (
          <Box component={'span'} sx={{ color: 'text.disabled', textDecoration: 'line-through', mr: 1 }}>
            {(selected.price.comparison / 100).toFixed(2)} €
          </Box>
        )}
        {(selected.price.sell / 100).toFixed(2)} €
      </Typography>
      <Divider sx={{ borderStyle: 'dashed', my: 3 }} />
      {product.variantOptions?.map((variantOption, variantOptionIndex) => (
        <Stack key={variantOption.title} direction={'row'} justifyContent={'space-between'} sx={{ mb: 3 }}>
          <Typography variant={'subtitle1'} sx={{ mt: 0.5 }}>
            {variantOption.title}
          </Typography>

          <Select<number>
            size={'small'}
            defaultValue={selected.variantOf?.index[variantOptionIndex]}
            onChange={event => {
              const value = event.target.value as number
              const nextIndex = [...(selected.variantOf?.index ?? [])]
              nextIndex[variantOptionIndex] = value
              const nextProduct = product.variants?.find(variant => isEqual(variant.variantOf?.index, nextIndex))
              if (nextProduct) {
                setSelected(nextProduct)
              }
            }}
            fullWidth={false}>
            {variantOption.values.map((option, optionIndex) => (
              <MenuItem key={option.label} value={optionIndex}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      ))}
      <Stack direction={'row'} justifyContent={'space-between'} sx={{ mb: 3 }}>
        <Typography variant={'subtitle1'} sx={{ mt: 0.5 }}>
          Anzahl
        </Typography>

        <div>
          <Incrementer
            name={'quantity'}
            quantity={quantity}
            available={available}
            onIncrementQuantity={() => setQuantity(quantity => quantity + 1)}
            onDecrementQuantity={() => setQuantity(quantity => quantity - 1)}
          />
          {selected.stock !== undefined && (
            <Typography variant={'caption'} component={'div'} sx={{ mt: 1, textAlign: 'right', color: 'text.secondary' }}>
              Verfügbar: {Math.max(available, 0)}
            </Typography>
          )}
        </div>
      </Stack>
      <Divider sx={{ borderStyle: 'dashed' }} />
      <Stack direction={'row'} spacing={2} sx={{ mt: 5 }}>
        <Button
          fullWidth
          disabled={quantity > available}
          size={'large'}
          color={'primary'}
          variant={'contained'}
          startIcon={<Icon icon={'ic:round-add-shopping-cart'} />}
          onClick={handleAdd}
          sx={{
            whiteSpace: 'nowrap',
            backgroundColor: color,
            '&:hover': {
              backgroundColor: darken(color, 0.1),
            },
          }}>
          Zum Warenkorb hinzufügen
        </Button>
      </Stack>
    </RootStyle>
  )
}

type IncrementerProps = {
  name: string
  quantity: number
  available: number
  onIncrementQuantity: VoidFunction
  onDecrementQuantity: VoidFunction
}

function Incrementer({ available, quantity, onIncrementQuantity, onDecrementQuantity }: IncrementerProps) {
  return (
    <Box
      sx={{
        py: 0.5,
        px: 0.75,
        border: 1,
        lineHeight: 0,
        borderRadius: 1,
        display: 'flex',
        alignItems: 'center',
        borderColor: 'grey.50032',
      }}>
      <IconButton size={'small'} color={'inherit'} disabled={quantity <= 1} onClick={onDecrementQuantity}>
        <Icon icon={'eva:minus-fill'} width={14} height={14} />
      </IconButton>

      <Typography variant={'body2'} component={'span'} sx={{ width: 40, textAlign: 'center' }}>
        {quantity}
      </Typography>

      <IconButton size={'small'} color={'inherit'} disabled={quantity >= available} onClick={onIncrementQuantity}>
        <Icon icon={'eva:plus-fill'} width={14} height={14} />
      </IconButton>
    </Box>
  )
}
