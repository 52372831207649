import { alpha, Box, CardActionArea, CardActionAreaProps, CardContent, CardMedia, Dialog, styled, Typography } from '@mui/material'
import React from 'react'

type ImageCardType = CardActionAreaProps & {
  imageSrc: string
  label?: string
  text?: string
  withDialog?: boolean
}

export const ImageCard: React.FC<ImageCardType> = ({ imageSrc, label, text, withDialog, children, ...props }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false)
  return (
    <StyledCardActionArea {...props} disableRipple>
      <Box>
        <CardMedia
          component={'img'}
          height={200}
          image={imageSrc}
          alt={'image'}
          onClick={() => {
            if (withDialog) {
              setDialogOpen(true)
            }
          }}
        />
        {label && <StyledTypography variant={'h5'}>{label}</StyledTypography>}
      </Box>
      {text ? (
        <CardContent sx={{ marginTop: label ? 5 : 0 }}>
          <Typography variant={'caption'}>{text}</Typography>
        </CardContent>
      ) : !text && children ? (
        <CardContent sx={{ marginTop: label ? 5 : 0 }}>{children}</CardContent>
      ) : null}
      <Dialog open={dialogOpen} maxWidth={'lg'} onClose={() => setDialogOpen(false)}>
        <img src={imageSrc} alt={'image'} style={{ width: '100%' }} />
      </Dialog>
    </StyledCardActionArea>
  )
}

const StyledCardActionArea = styled(CardActionArea)(({ theme }) => ({
  boxShadow: theme.shadows['5'],
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  textAlign: 'center',
  color: theme.palette.background.default,
  paddingTop: '5px',
  paddingBottom: '5px',
  backgroundColor: alpha(theme.palette.background.contrast, 0.9),
}))
