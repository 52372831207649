import { styled } from '@mui/material'
import Head from 'next/head'
import React from 'react'

export const BannerImage = React.forwardRef<HTMLImageElement, React.ComponentProps<typeof StyledImage>>((props, ref) => (
  <React.Fragment>
    <Head>
      <meta name={'og:image'} content={props.src ?? '/static/images/drone.jpg'} />
    </Head>
    <StyledImage ref={ref} {...props} />
  </React.Fragment>
))

const StyledImage = styled('img')(() => ({
  width: '100%',
  height: '40vh',
  maxHeight: 340,
  objectFit: 'cover',
  objectPosition: 'center',
}))
