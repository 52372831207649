import { styled } from '@mui/material'
import * as React from 'react'

type MobileToggleProps = React.ComponentProps<typeof Container> & {
  active: boolean
}

export const MobileToggle: React.FC<MobileToggleProps> = ({ active, ...props }) => {
  return (
    <Container {...props}>
      <Bar sx={active ? { transform: 'rotate(45deg)', top: 12 } : undefined} />
      <Bar sx={active ? { background: 'transparent' } : undefined} />
      <Bar sx={active ? { transform: 'rotate(-45deg)', top: -12 } : undefined} />
    </Container>
  )
}

const Container = styled('div')(() => ({
  cursor: 'pointer',
}))

const Bar = styled('div')(() => ({
  position: 'relative',
  top: 0,
  width: 45,
  height: 3,
  backgroundColor: 'white',
  margin: '9px 0',
  borderRadius: 2,
  transitionDuration: '200ms',
}))
