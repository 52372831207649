import { Box, Button, ButtonProps, Collapse, Stack, styled } from '@mui/material'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import * as React from 'react'

import { Icon } from '../../Icon/Icon'
import { NavEntryProps } from './NavEntry'

export const MobileNavEntry: React.FC<NavEntryProps & { onClick: () => void }> = ({ title, href, subEntries, onClick }) => {
  const router = useRouter()
  const active = React.useMemo(() => {
    return href === '/' ? router.asPath === href : href ? router.asPath.startsWith(href) : false
  }, [href, router.asPath])

  const [collapsed, setCollapsed] = React.useState(true)

  return (
    <React.Fragment>
      {href ? (
        <NextLink href={href} passHref>
          <StyledButton active={active} onClick={onClick}>
            {title}
          </StyledButton>
        </NextLink>
      ) : (
        <StyledButton active={active} onClick={() => setCollapsed(!collapsed)}>
          {title}
          <Box sx={{ ml: 1 }}>
            <ChevronIcon icon={'akar-icons:chevron-down'} sx={{ transform: `rotate(${collapsed ? 0 : '180deg'})` }} />
          </Box>
        </StyledButton>
      )}
      {subEntries && (
        <Collapse in={!collapsed}>
          <Stack direction={'column'}>
            {subEntries.map(entry => (
              <NextLink key={entry.href} href={entry.href} passHref>
                <StyledButton sx={{ pl: 4 }} onClick={onClick}>
                  {entry.title}
                </StyledButton>
              </NextLink>
            ))}
          </Stack>
        </Collapse>
      )}
    </React.Fragment>
  )
}

const StyledButton = styled(
  React.forwardRef<HTMLButtonElement, ButtonProps & { active?: boolean }>(({ active, ...props }, ref) => <Button ref={ref} {...props} color={'inherit'} disableRipple />)
)(({ active, theme }) => ({
  color: theme.palette.background.default,
  textDecoration: active ? 'underline' : 'none',
  justifyContent: 'flex-start',
}))

const ChevronIcon = styled(Icon)(({ theme }) => ({
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.standard,
  }),
}))
