import { createTheme, CssBaseline, ThemeOptions, ThemeProvider as MUIThemeProvider } from '@mui/material'
import * as React from 'react'

import { breakpoints } from './breakpoints'
import { GlobalStyles } from './globalStyles'
import { ComponentsOverrides } from './overrides'
import { palette } from './palette'
import { customShadows, shadows } from './shadows'
import { typography } from './typography'

export const ThemeProvider: React.FC = ({ children }) => {
  const themeOptions: ThemeOptions = React.useMemo(
    () => ({
      palette: palette.light,
      typography,
      breakpoints,
      shape: { borderRadius: 8 },
      direction: 'ltr',
      shadows: shadows.light,
      customShadows: customShadows.light,
    }),
    []
  )

  const theme = createTheme(themeOptions)
  theme.components = ComponentsOverrides(theme)

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles />
      {children}
    </MUIThemeProvider>
  )
}
