import { Box, Step, StepConnector, StepLabel, Stepper, StepperProps } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'

import { Icon } from '../Icon/Icon'

type CheckoutStepperProps = StepperProps & {
  initialLabel: string
  paymentLabel?: string
  confirmationLabel?: string
}

export const CheckoutStepper: React.FC<CheckoutStepperProps> = ({ initialLabel, paymentLabel = 'Zahlungsdaten hinterlegen', confirmationLabel = 'Bestätigung', ...props }) => (
  <Stepper alternativeLabel connector={<StyledConnector />} {...props} sx={{ width: 1, maxWidth: 800, margin: '0 auto', ...props.sx }}>
    {[initialLabel, paymentLabel, confirmationLabel].map(label => (
      <Step key={label}>
        <StepLabel
          StepIconComponent={StyledStepIcon}
          sx={{
            '& .MuiStepLabel-label': {
              typography: 'subtitle2',
              color: 'text.disabled',
            },
          }}>
          {label}
        </StepLabel>
      </Step>
    ))}
  </Stepper>
)

const StyledConnector = styled(StepConnector)(({ theme }) => ({
  top: 10,
  left: 'calc(-50% + 20px)',
  right: 'calc(50% + 20px)',
  '& .MuiStepConnector-line': {
    borderTopWidth: 2,
    borderColor: theme.palette.divider,
  },
  '&.Mui-active, &.Mui-completed': {
    '& .MuiStepConnector-line': {
      borderColor: theme.palette.primary.main,
    },
  },
}))

function StyledStepIcon({ active, completed }: { active: boolean; completed: boolean }) {
  return (
    <Box
      sx={{
        zIndex: 9,
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: active ? 'primary.main' : 'text.disabled',
      }}>
      {completed ? (
        <Icon icon={'eva:checkmark-fill'} sx={{ zIndex: 1, width: 20, height: 20, color: 'primary.main' }} />
      ) : (
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: 'currentColor',
          }}
        />
      )}
    </Box>
  )
}
