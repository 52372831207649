import { Box, Divider, Grid, Link, LinkProps, Stack, styled, Typography, TypographyProps } from '@mui/material'
import Image from 'next/image'
import NextLink from 'next/link'
import React from 'react'

import { Container } from '../Container/Container'
import { Icon } from '../Icon/Icon'
import vdwsImage from './images/vdws.png'

export const Footer: React.FC = () => {
  return (
    <Background>
      <Container>
        <Stack direction={'column'} gap={1} py={4}>
          <Grid container>
            <Grid item md={4} xs={12} textAlign={'center'}>
              <Typography typography={'h6'} sx={{ marginBottom: '20px' }}>
                Öffnungszeiten
              </Typography>
              <Text>
                <b>Café:</b>
                <br />
                Mo-Fr 12 - 18 Uhr <br />
                Sa-So 11 - 18 Uhr <br />
              </Text>
              <Text mt={2}>
                <b>Speisen und Cocktails:</b>
                <br />
                Mo-So 12 - 19 Uhr <br />
              </Text>
              <Text mt={2}>
                <b>Surfshop:</b>
                <br />
                Mo-Fr 12 - 18 Uhr <br />
                Sa-So 11 - 18 Uhr <br />
              </Text>
              <Text mt={2}>
                <b>bei Schietwedda geschlossen</b>
              </Text>

              <StyledDivider sx={{ display: { sx: 'block', md: 'none' } }} />
            </Grid>
            <Grid item md={4} xs={12} textAlign={'center'}>
              <Typography typography={'h6'} sx={{ marginBottom: '20px' }}>
                Nützliche Links
              </Typography>
              <Stack>
                <NextLink href={'/faq'} passHref>
                  <StyledLinks>FAQ - Häufige Fragen</StyledLinks>
                </NextLink>
                <NextLink href={'/kontakt'} passHref>
                  <StyledLinks>Kontakt</StyledLinks>
                </NextLink>
                <NextLink href={'/jobs'} passHref>
                  <StyledLinks>Jobs</StyledLinks>
                </NextLink>
                <StyledLinks
                  href={
                    'https://www.google.de/maps/place/Tatort+Hawaii/@54.4177617,10.2645905,17z/data=!3m1!4b1!4m5!3m4!1s0x47b2f75f11ec4ddf:0xcb7339fd261d2858!8m2!3d54.4177586!4d10.2667792'
                  }
                  target={'_blank'}
                  rel={'noreferrer'}>
                  Anfahrt
                </StyledLinks>
                <NextLink href={'/impressum'} passHref>
                  <StyledLinks>Impressum</StyledLinks>
                </NextLink>
                <NextLink href={'/datenschutz'} passHref>
                  <StyledLinks>Datenschutzerklärung</StyledLinks>
                </NextLink>
                <NextLink href={'/agb'} passHref>
                  <StyledLinks>Allgemeine Geschäftsbedingungen</StyledLinks>
                </NextLink>
              </Stack>
              <Box>
                <Typography typography={'h6'} sx={{ marginTop: '20px', marginBottom: '10px' }}>
                  Social Media
                </Typography>
                <Stack direction={'row'} gap={1} justifyContent={'center'} alignItems={'center'}>
                  <FacebookIcon href={'https://facebook.com/pages/category/High-School/Tatort-Hawaii-311164388780/'} rel={'noreferrer'} target={'_blank'} />
                  <InstagramIcon href={'https://www.instagram.com/TatortHawaii'} target={'_blank'} rel={'noreferrer'} />
                </Stack>
              </Box>
              <StyledDivider sx={{ display: { sx: 'block', md: 'none' } }} />
            </Grid>
            <Grid item md={4} xs={12} textAlign={'center'}>
              <Typography typography={'h6'} sx={{ paddingBottom: '20px' }}>
                Kontakt
              </Typography>
              <Text>
                Telefon:
                <br />
                <StyledLinks href={'tel:+491794687214'}>+49 179 4687214</StyledLinks>
              </Text>
              <Text my={2}>
                E-Mail:
                <br />
                <StyledLinks href={'mailto:info@tatort-hawaii.de'}>info@tatort-hawaii.de</StyledLinks>
              </Text>
              <Text fontWeight={'bold'} sx={{ pt: 2, pb: 1 }}>
                Lizensiertes VDWS-Wassersportcenter
              </Text>
              <a href={'https://www.vdws.de/'} target={'_blank'} rel={'noopener noreferrer'}>
                <Image src={vdwsImage} alt={'VDWS Logo'} width={200} height={80} objectFit={'contain'} />
              </a>
            </Grid>
          </Grid>
          <StyledDivider />
          <Box textAlign={'center'}>
            <Typography variant={'caption'}>
              Copyright © {new Date().getFullYear()}{' '}
              <StyledLinks variant={'caption'} href={'https://b4online.de'} target={'_blank'} rel={'noreferrer'} style={{ textDecoration: 'none' }}>
                B4online GmbH
              </StyledLinks>
              . Alle Rechte vorbehalten.
            </Typography>
          </Box>
        </Stack>
      </Container>
    </Background>
  )
}

const Background = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.contrast,
  color: theme.palette.background.default,
}))

const StyledLinks = styled(React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => <Link ref={ref} variant={'body2'} {...props} />))(() => ({
  color: 'white',
  textDecoration: 'underline',
}))

const StyledDivider = styled(Divider)(({ theme }) => ({
  borderWidth: '1px',
  borderColor: 'white',
  width: `calc(100% - ${theme.spacing(2)} * 2)`,
  margin: theme.spacing(2, 'auto'),
}))

const Text = styled((props: TypographyProps) => <Typography variant={'body2'} {...props} />)(() => ({}))

const FacebookIcon = styled((props: React.ComponentProps<'a'>) => {
  return (
    <a {...props}>
      <Icon icon={'fa-brands:facebook-f'} />
    </a>
  )
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: `1px solid ${theme.palette.background.default}`,
  borderRadius: 999,
  color: theme.palette.background.default,
  width: 36,
  height: 36,
  transition: theme.transitions.create('background-color', {
    duration: theme.transitions.duration.short,
  }),
  '&:hover': {
    backgroundColor: '#3b5998',
  },
}))

const InstagramIcon = styled((props: React.ComponentProps<'a'>) => {
  return (
    <a {...props}>
      <Icon icon={'fa6-brands:instagram'} />
    </a>
  )
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: `1px solid ${theme.palette.background.default}`,
  borderRadius: 999,
  color: theme.palette.background.default,
  width: 36,
  height: 36,
  '&:hover': {
    background: 'linear-gradient(45deg, #f9d46d 0%, #b83272 60%, #6148d0 80%, #9a2fa3 100%)',
  },
}))
