import { Paper, PaperProps, Typography } from '@mui/material'
import React from 'react'

interface SearchNotFoundProps extends PaperProps {
  searchQuery?: string
}

export const SearchNotFound: React.FC<SearchNotFoundProps> = ({ searchQuery = '', ...other }) => {
  return searchQuery ? (
    <Paper {...other}>
      <Typography gutterBottom align={'center'} variant={'subtitle1'}>
        Keine Treffer
      </Typography>
      <Typography variant={'body2'} align={'center'}>
        Es wurden keine Produkte für den Suchbegriff &nbsp;
        <strong>&quot;{searchQuery}&quot;</strong> gefunden.
      </Typography>
    </Paper>
  ) : (
    <Typography variant={'body2'}>Gib einen Suchbegriff ein</Typography>
  )
}
