import { Box, BoxProps, styled } from '@mui/material'
import React from 'react'

import { useResponsive } from '../../hooks'

type FullImageContainerProps = {
  imageSrc: string
  fixed?: boolean
  height?: string
}

export const FullImageContainer: React.FC<FullImageContainerProps> = ({ imageSrc, fixed = false, height }) => {
  const isMobile = useResponsive('down', 'md')

  return (
    <StyledBox style={{ height: height !== undefined ? height + 'vh' : '30vh' }}>
      <Image fixed={fixed && !isMobile} sx={{ backgroundImage: `url(${imageSrc})` }} />
    </StyledBox>
  )
}

const StyledBox = styled(Box)(() => ({
  height: '30vh',
  padding: 0,
  boxShadow: 'none',
}))

const Image = styled(({ fixed, ...props }: BoxProps & { fixed: boolean }) => <Box {...props} />)(({ fixed }) => ({
  position: 'relative',
  top: '-2px',
  height: 'calc(100% + 4px)',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: fixed ? 'fixed' : undefined,
}))
