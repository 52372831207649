import { MapkitProvider as AppleMapkitProvider } from '@b4online/uikit'
import React from 'react'

import { api } from '../api'

export const MapkitProvider: React.FC = ({ children }) => {
  const resolveRef = React.useRef<(value: string) => void>()
  const rejectRef = React.useRef<(reason: any) => void>()
  const mapTokenPromise = React.useMemo(
    () =>
      new Promise<string>((resolve, reject) => {
        resolveRef.current = resolve
        rejectRef.current = reject
      }),
    []
  )
  React.useEffect(() => {
    api.maps
      .retrieveToken()
      .then(response => {
        if (response.result === 'success') {
          resolveRef.current?.(response.token)
        } else {
          rejectRef.current?.(response.message)
        }
      })
      .catch(reason => rejectRef.current?.(reason))
  }, [])
  return (
    <AppleMapkitProvider tokenOrCallback={() => mapTokenPromise} language={'de'}>
      {children}
    </AppleMapkitProvider>
  )
}
