import { alpha, CardActionArea, CardMedia, styled, Typography } from '@mui/material'
import React from 'react'

type ImageButton = {
  imageSrc: string
  label: string
  onClick?: () => void
}

export const ImageButton: React.FC<ImageButton> = ({ imageSrc, label, onClick }) => {
  return (
    <StyledCardActionArea onClick={onClick} disableRipple>
      <CardMedia component={'img'} height={320} image={imageSrc} alt={'image'} />
      <StyledTypography variant={'h6'}>{label}</StyledTypography>
    </StyledCardActionArea>
  )
}

const StyledCardActionArea = styled(CardActionArea)(({ theme }) => ({
  boxShadow: theme.shadows['5'],
  borderRadius: 6,
  overflow: 'hidden',
  '> img': {
    transition: 'transform 0.3s ease-in-out',
  },
  '&:hover': {
    '> img': {
      transform: 'scale(1.1)',
    },
  },
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  width: '100%',
  textAlign: 'center',
  color: theme.palette.background.default,
  paddingTop: 24,
  paddingBottom: 24,
  backgroundColor: alpha(theme.palette.background.contrast, 0.9),
  textTransform: 'uppercase',
}))
