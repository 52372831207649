import { Box, Collapse, Stack, styled } from '@mui/material'
import { useRouter } from 'next/router'
import * as React from 'react'

import { MobileNavEntry } from './components/MobileNavEntry'
import { MobileToggle } from './components/MobileToggle'
import { NavEntry, NavEntryProps } from './components/NavEntry'

const entries: NavEntryProps[] = [
  { title: 'Start', href: '/' },
  { title: 'News', href: '/news' },
  { title: 'Über uns', href: '/about' },
  { href: '/buchen/strandkorb', title: 'Strandkörbe' },
  { href: '/surfschule', title: 'Surfschule' },
  {
    title: 'Angebote',
    subEntries: [
      { href: '/gastronomie', title: 'Gastronomie' },
      { href: '/events', title: 'Events' },
      { href: '/hochzeiten', title: 'Hochzeiten' },
      { href: '/konzerte', title: 'Konzerte' },
    ],
  },
  { title: 'Shop', href: '/shop' },
  { title: 'Jobs', href: '/jobs' },
  { title: 'Kontakt', href: '/kontakt' },
]

export const Navigation: React.FC = () => {
  const router = useRouter()
  const [active, setActive] = React.useState(false)

  React.useEffect(() => {
    setActive(false)
  }, [router.asPath])

  return (
    <React.Fragment>
      <Box sx={{ height: 79 }} />
      <NavStyles>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ height: 1 }}>
          <Logo src={'/static/logo.svg'} onClick={() => router.push('/')} sx={{ cursor: 'pointer' }} />
          <Stack direction={'row'} gap={2} sx={{ display: { xs: 'none', md: 'block' } }}>
            {entries.map((entry, index) => (
              <NavEntry key={index} {...entry} />
            ))}
          </Stack>
          <MobileToggle active={active} onClick={() => setActive(!active)} sx={{ display: { xs: 'block', md: 'none' } }} />
        </Stack>
      </NavStyles>
      <MobileContainer sx={{ display: { xs: 'block', md: 'none' } }}>
        <Collapse in={active}>
          <Stack direction={'column'} px={2} pb={2}>
            {entries.map((entry, index) => (
              <MobileNavEntry key={index} {...entry} onClick={() => setActive(false)} />
            ))}
          </Stack>
        </Collapse>
      </MobileContainer>
    </React.Fragment>
  )
}

const NavStyles = styled('nav')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: 80,
  backgroundColor: theme.palette.background.contrast,
  zIndex: 1000,
  userSelect: 'none',
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
}))

const Logo = styled('img')(() => ({
  height: 48,
}))

const MobileContainer = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 79,
  left: 0,
  width: '100%',
  backgroundColor: theme.palette.background.contrast,
  color: theme.palette.background.default,
  zIndex: 1000,
}))
