import { useWebStatistics } from '@b4online/statistics-react'
import { CacheProvider, EmotionCache } from '@emotion/react'
import { LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import deLocale from 'date-fns/locale/de'
import { capitalize } from 'lodash'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { Router, useRouter } from 'next/router'
import * as NProgress from 'nprogress'
import React from 'react'

import { customer } from '../api'
import { Navigation } from '../components'
import { Footer } from '../components/Footer/Footer'
import { MapkitProvider } from '../providers/MapkitProvider'
import { ThemeProvider } from '../theme'
import { createEmotionCache } from '../utils'

NProgress.configure({ showSpinner: false })

Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

const clientSideEmotionCache = createEmotionCache()

interface AppOverrideProps extends Omit<AppProps, 'Component'> {
  Component: React.FC
  emotionCache?: EmotionCache
}

const AppOverride: React.FC<AppOverrideProps> = ({ Component, pageProps, emotionCache = clientSideEmotionCache }) => {
  const router = useRouter()
  const title = router.asPath === '/' ? 'Tatort-Hawaii' : `Tatort-Hawaii - ${capitalize(decodeURIComponent(router.asPath.split('/')[1]))}`
  useWebStatistics(customer, router.asPath)

  return (
    <React.Fragment>
      <Head>
        <title>{title}</title>
        <meta name={'viewport'} content={'width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1, user-scalable=no'} />
        <meta property={'og:type'} content={'website'} />
        <meta property={'og:site_name'} content={title} />
        <meta property={'og:title'} content={title} />
        <meta property={'twitter:title'} content={title} />
        <meta name={'apple-mobile-web-app-title'} content={title} />
        <meta name={'application-name'} content={title} />
        <meta
          name={'description'}
          content={'Windsurfen, Kitesurfen und SUP lernen am optimalen Ostseestrand in Stein umgeben von Konzerten, Events und einem Strandafé im entspannten Ambiente.'}
        />
      </Head>
      <CacheProvider value={emotionCache}>
        <ThemeProvider>
          <MapkitProvider>
            <LocalizationProvider locale={deLocale} dateAdapter={AdapterDateFns}>
              <Navigation />
              <Component {...pageProps} />
              <Footer />
            </LocalizationProvider>
          </MapkitProvider>
        </ThemeProvider>
      </CacheProvider>
    </React.Fragment>
  )
}

export default AppOverride
