import { alpha, Button, ButtonProps, Menu, MenuItem, MenuProps, styled } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'

export type NavEntryProps =
  | {
      href: string
      title: string
      subEntries?: undefined
    }
  | {
      href?: undefined
      title: string
      subEntries: Array<{ href: string; title: string }>
    }

export const NavEntry: React.FC<NavEntryProps> = ({ href, title, subEntries }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const router = useRouter()
  const active = React.useMemo(() => {
    return href === '/' ? router.asPath === href : href ? router.asPath.startsWith(href) : false
  }, [href, router.asPath])
  return (
    <React.Fragment>
      {href ? (
        <NextLink href={href} passHref>
          <StyledButton active={active}>{title}</StyledButton>
        </NextLink>
      ) : (
        <StyledButton active={active} onClick={event => setAnchorEl(event.currentTarget)}>
          {title}
        </StyledButton>
      )}
      {subEntries !== undefined && (
        <StyledMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
          {subEntries.map(entry => (
            <NextLink key={entry.href} href={entry.href} passHref>
              <MenuItem key={entry.href} onClick={() => setAnchorEl(null)} disableRipple>
                {entry.title}
              </MenuItem>
            </NextLink>
          ))}
        </StyledMenu>
      )}
    </React.Fragment>
  )
}

const StyledButton = styled(
  React.forwardRef<HTMLButtonElement, ButtonProps & { active: boolean }>(({ active, ...props }, ref) => <Button ref={ref} {...props} color={'inherit'} disableRipple />)
)(({ active, theme }) => ({
  color: theme.palette.background.default,
  textDecoration: active ? 'underline' : 'none',
}))

const StyledMenu = styled((props: MenuProps) => {
  const theme = useTheme()
  return (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transitionDuration={theme.transitions.duration.shortest}
      {...props}
    />
  )
})(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}))
