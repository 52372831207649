import 'yup-phone'

import { Payments } from '@b4online/api-common'
import checkmarkCircle2Fill from '@iconify/icons-eva/checkmark-circle-2-fill'
import { Icon } from '@iconify/react'
import { Alert, LoadingButton } from '@mui/lab'
import { Box, Collapse, Divider, FormControlLabel, FormHelperText, Paper, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { CardElement, IbanElement, PaymentRequestButtonElement, useElements, useStripe } from '@stripe/react-stripe-js'
import {
  PaymentMethodCreateParams,
  PaymentRequest,
  PaymentRequestItem,
  PaymentRequestPaymentMethodEvent,
  Stripe,
  StripePaymentRequestButtonElementOptions,
} from '@stripe/stripe-js'
import { Form, FormikProvider, useFormik } from 'formik'
import React from 'react'
import * as yup from 'yup'

import { StripeElementInput, StripeElementInputState } from './StripeElementInput'

const ibanDisclaimer =
  'Indem Sie Ihre IBAN angeben und diese Zahlung bestätigen, ermächtigen Sie Stripe, unseren Zahlungsdienstleister, Anweisungen an Ihre Bank zu senden, um Ihr Konto gemäß diesen Anweisungen zu belasten. Sie haben Anspruch auf eine Rückerstattung von Ihrer Bank gemäß den Bedingungen Ihrer Vereinbarung mit Ihrer Bank. Eine Rückerstattung muss innerhalb von acht Wochen ab dem Datum der Belastung Ihres Kontos beantragt werden.'

const AddressHelperTextStyles = styled(FormHelperText)(() => ({
  marginLeft: 14,
  marginRight: 14,
}))

const OptionStyle = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2),
  transition: theme.transitions.create('all'),
  border: `solid 1px ${theme.palette.grey[500_32]}`,
}))

type InitialValues = Partial<{
  name: string
  email: string
  phone: string
  addressLine: string
  addressZip: string
  addressCity: string
  method: 'card' | 'sepa' | 'free' | 'unset'
  card: StripeElementInputState
  sepa: StripeElementInputState
  afterSubmit?: string
}>

const schema = yup.object({
  name: yup.string().required('Bitte gib einen Namen an'),
  email: yup.string().email('Bitte gib eine E-Mail Adresse an').required('Bitte gib eine E-Mail Adresse an'),
  phone: yup.string().phone('DE', false, 'Bitte gib eine Telefonnummer an').required('Bitte gib eine Telefonnummer an'),
  addressLine: yup.string().required('Bitte gib eine Adresse an'),
  addressZip: yup.string().required('Bitte gib eine Postleitzahl an'),
  addressCity: yup.string().required('Bitte gib einen Ort an'),
  method: yup.mixed().oneOf(['card', 'sepa', 'free']),
  card: yup.mixed().when('method', {
    is: 'card',
    then: yup.mixed().oneOf(['complete']),
    otherwise: yup.mixed(),
  }),
  sepa: yup.mixed().when('method', {
    is: 'sepa',
    then: yup.mixed().oneOf(['complete']),
    otherwise: yup.mixed(),
  }),
})

export type CheckoutFormSubmitFn<T> = (
  method: 'card' | 'sepa' | 'free',
  billingDetails: Payments.ContactDetails,
  complete: (clientSecret: string | undefined, data: T) => Promise<void>
) => Promise<void>

export type CheckoutPayerInfo = Required<Pick<PaymentRequestPaymentMethodEvent, 'payerName' | 'payerEmail' | 'payerPhone'>>

export type CheckoutFormProps<T = unknown> = {
  total: PaymentRequestItem
  displayItems: PaymentRequestItem[] | undefined
  onSubmit: CheckoutFormSubmitFn<T>
  onComplete: (info: CheckoutPayerInfo, result: T) => void
  paymentButtonType: 'book' | 'buy' | 'order'
}

export const CheckoutForm = <T,>({ onSubmit, onComplete, ...props }: CheckoutFormProps<T>) => {
  const theme = useTheme()
  const stripe = useStripe()
  const elements = useElements()
  const isFree = props.total.amount === 0
  const paymentRequest = usePaymentRequest(stripe, props, async (stripe, event) => {
    const { payerName, payerEmail, payerPhone } = event
    if (!payerName || !payerEmail || !payerPhone) throw Error('Name, E-Mail Adresse oder Telefonnummer nicht durch Wallet übertragen')
    const billingDetails: Payments.ContactDetails = {
      name: payerName,
      email: payerEmail,
      phone: payerPhone,
      address: {
        line: event.paymentMethod.billing_details.address?.line1 || '',
        zip: event.paymentMethod.billing_details.address?.postal_code || '',
        city: event.paymentMethod.billing_details.address?.city || '',
        state: event.paymentMethod.billing_details.address?.state || '',
        country: event.paymentMethod.billing_details.address?.country || '',
      },
    }
    try {
      await onSubmit('card', billingDetails, async (clientSecret, result) => {
        if (!clientSecret) {
          throw Error('Unable to retrieve client secret')
        }
        const response = await stripe.confirmCardPayment(
          clientSecret,
          {
            payment_method: event.paymentMethod.id,
          },
          {
            handleActions: true,
          }
        )
        if (response.error) {
          throw Error(response.error.message ?? 'Es ist ein unbekannter Fehler aufgetreten')
        } else {
          event.complete('success')
          await onComplete({ payerName, payerEmail, payerPhone }, result)
        }
      })
    } catch (e) {
      event.complete('fail')
    }
  })
  const paymentRequestOptions = usePaymentRequestButtonOptions(paymentRequest, props.paymentButtonType)

  const formik = useFormik<InitialValues>({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      addressLine: '',
      addressZip: '',
      addressCity: '',
      method: isFree ? 'free' : 'unset',
      card: 'empty',
      sepa: 'empty',
      afterSubmit: 'Es ist ein unerwarteter Fehler aufgetreten',
    },
    validationSchema: schema,
    onSubmit: async (raw, { setErrors }) => {
      if (stripe === null || elements === null) return
      const values = schema.validateSync(raw)

      const billingDetails: Payments.ContactDetails = {
        name: values.name,
        email: values.email,
        phone: values.phone,
        address: {
          line: values.addressLine,
          zip: values.addressZip,
          city: values.addressCity,
          state: '',
          country: '',
        },
      }

      const stripeBillingDetails: Required<PaymentMethodCreateParams.BillingDetails> = {
        name: values.name,
        email: values.email,
        phone: values.phone,
        address: {
          line1: values.addressLine,
          postal_code: values.addressZip,
          city: values.addressCity,
        },
      }

      try {
        await onSubmit(values.method, billingDetails, async (clientSecret, result) => {
          switch (values.method) {
            case 'card':
              if (!clientSecret) {
                throw Error('Unable to retrieve client secret')
              }
              const card = elements.getElement(CardElement)
              if (card === null) {
                throw Error('Unable to get card element')
              }
              const cardResponse = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                  card: card,
                  billing_details: stripeBillingDetails,
                },
              })
              if (cardResponse.error) {
                throw Error(cardResponse.error.message ?? 'Es ist ein unbekannter Fehler aufgetreten')
              }
              break
            case 'sepa':
              if (!clientSecret) {
                throw Error('Unable to retrieve client secret')
              }
              const sepaDebit = elements.getElement(IbanElement)
              if (sepaDebit === null) {
                throw Error('Unable to get sepa element')
              }
              const sepaResponse = await stripe.confirmSepaDebitPayment(clientSecret, {
                payment_method: {
                  sepa_debit: sepaDebit,
                  billing_details: stripeBillingDetails,
                },
              })
              if (sepaResponse.error) {
                throw Error(sepaResponse.error.message ?? 'Es ist ein unbekannter Fehler aufgetreten')
              }
              break
          }
          await onComplete(
            {
              payerName: billingDetails.name,
              payerEmail: billingDetails.email,
              payerPhone: billingDetails.phone,
            },
            result
          )
        })
      } catch (e) {
        setErrors({ afterSubmit: (e as Error).message })
      }
    },
  })
  const { getFieldProps, setFieldValue, handleSubmit, values, errors, touched, isSubmitting } = formik

  return (
    <FormikProvider value={formik}>
      <Form noValidate onSubmit={handleSubmit}>
        {errors.afterSubmit && (
          <Alert severity={'error'} sx={{ mb: 2 }}>
            {errors.afterSubmit}
          </Alert>
        )}
        {!isFree && !!paymentRequest && !!paymentRequestOptions && (
          <React.Fragment>
            <PaymentRequestButtonElement key={theme.palette.mode} options={paymentRequestOptions} />
            <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} sx={{ py: 1 }} spacing={1}>
              <Divider sx={{ flexGrow: 1 }} />
              <Typography variant={'caption'}>oder gib deine Zahlungsinformationen ein</Typography>
              <Divider sx={{ flexGrow: 1 }} />
            </Stack>
          </React.Fragment>
        )}
        <Stack direction={'column'} spacing={2}>
          <TextField {...getFieldProps('name')} label={'Name'} size={'small'} error={touched.name && !!errors.name} helperText={touched.name && errors.name} />
          <TextField
            {...getFieldProps('email')}
            label={'E-Mail'}
            size={'small'}
            type={'email'}
            error={touched.email && !!errors.email}
            helperText={touched.email && errors.email}
          />
          <TextField {...getFieldProps('phone')} label={'Telefon'} size={'small'} type={'tel'} error={touched.phone && !!errors.phone} helperText={touched.phone && errors.phone} />
          <Stack direction={'column'}>
            <Box display={'grid'} gridTemplateColumns={'1fr 80px 80px'} gap={1}>
              <TextField {...getFieldProps('addressLine')} label={'Adresse'} size={'small'} error={touched.addressLine && !!errors.addressLine} fullWidth />
              <TextField
                {...getFieldProps('addressZip')}
                label={'Plz'}
                placeholder={'Postal code'}
                size={'small'}
                onFocus={event => {
                  event.target.placeholder = ''
                }}
                error={touched.addressZip && !!errors.addressZip}
                fullWidth
              />
              <TextField {...getFieldProps('addressCity')} label={'Ort'} size={'small'} error={touched.addressCity && !!errors.addressCity} fullWidth />
            </Box>
            {((touched.addressLine && !!errors.addressLine) || (touched.addressZip && !!errors.addressZip) || (touched.addressCity && !!errors.addressCity)) && (
              <AddressHelperTextStyles error>{errors.addressLine || errors.addressZip || errors.addressCity}</AddressHelperTextStyles>
            )}
          </Stack>
        </Stack>
        {isFree ? (
          <Alert severity={'success'} sx={{ mt: 3 }}>
            Keine Zahlung erforderlich
          </Alert>
        ) : (
          <RadioGroup value={values.method} onChange={(_, value) => setFieldValue('method', value)}>
            <Stack direction={'column'} spacing={1} sx={{ pt: 3 }}>
              <OptionStyle
                sx={{
                  flexWrap: 'wrap',
                  ...(values.method === 'sepa' && {
                    boxShadow: theme => theme.customShadows.z8,
                  }),
                }}>
                <FormControlLabel
                  value={'sepa'}
                  control={<Radio checkedIcon={<Icon icon={checkmarkCircle2Fill} />} />}
                  label={
                    <Typography variant={'subtitle2'} sx={{ ml: 1 }}>
                      SEPA Lastschrift
                    </Typography>
                  }
                  sx={{ py: 1, mx: 0 }}
                />
                <Collapse in={values.method === 'sepa'} sx={{ width: 1 }}>
                  <Box sx={{ pb: 2 }}>
                    <StripeElementInput
                      component={{ type: 'sepa', component: IbanElement }}
                      label={'IBAN'}
                      size={'small'}
                      onStateChange={state => setFieldValue('sepa', state)}
                      fullWidth
                    />
                    <Typography display={'block'} variant={'caption'} align={'center'} sx={{ pt: 2 }}>
                      {ibanDisclaimer}
                    </Typography>
                  </Box>
                </Collapse>
              </OptionStyle>
              <OptionStyle
                sx={{
                  flexWrap: 'wrap',
                  ...(values.method === 'card' && {
                    boxShadow: theme => theme.customShadows.z8,
                  }),
                }}>
                <FormControlLabel
                  value={'card'}
                  control={<Radio checkedIcon={<Icon icon={checkmarkCircle2Fill} />} />}
                  label={
                    <Typography variant={'subtitle2'} sx={{ ml: 1 }}>
                      Kreditkarte
                    </Typography>
                  }
                  sx={{ py: 1, mx: 0 }}
                />
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                  <img alt={'logo card'} src={'/static/icons/ic_mastercard.svg'} />
                  <img alt={'logo card'} src={'/static/icons/ic_visa.svg'} />
                </Stack>
                <Collapse in={values.method === 'card'} sx={{ width: 1 }}>
                  <Box sx={{ pb: 2 }}>
                    <StripeElementInput
                      component={{ type: 'card', component: CardElement }}
                      label={'Kartennummer'}
                      size={'small'}
                      onStateChange={state => setFieldValue('card', state)}
                      fullWidth
                    />
                  </Box>
                </Collapse>
              </OptionStyle>
            </Stack>
          </RadioGroup>
        )}
        <Box sx={{ pt: 3 }}>
          <LoadingButton variant={'contained'} color={'secondary'} type={'submit'} loading={isSubmitting} fullWidth>
            {((props.total.amount ?? 0) / 100).toFixed(2)} € {isFree ? 'bestätigen' : 'bezahlen'}
          </LoadingButton>
        </Box>
      </Form>
    </FormikProvider>
  )
}

const usePaymentRequest = (
  stripe: Stripe | null,
  props: Pick<CheckoutFormProps, 'total' | 'displayItems'>,
  onMethod: (stripe: Stripe, event: PaymentRequestPaymentMethodEvent) => void
): PaymentRequest | undefined => {
  const [canMakePayment, setCanMakePayment] = React.useState(false)
  const [paymentRequest, setPaymentRequest] = React.useState<PaymentRequest>()
  React.useEffect(() => {
    if (stripe === null || props.displayItems?.some(item => !!item.pending)) return
    const paymentRequest = stripe.paymentRequest({
      country: 'DE',
      currency: 'eur',
      total: props.total,
      displayItems: props.displayItems,
      requestPayerName: true,
      requestPayerEmail: true,
      requestPayerPhone: true,
    })
    paymentRequest.canMakePayment().then(result => setCanMakePayment(!!result))
    paymentRequest.on('paymentmethod', event => onMethod(stripe, event))
    setPaymentRequest(paymentRequest)
  }, [stripe, props.total, props.displayItems])
  return canMakePayment ? paymentRequest : undefined
}

const usePaymentRequestButtonOptions = (
  paymentRequest: PaymentRequest | undefined,
  type: CheckoutFormProps['paymentButtonType']
): StripePaymentRequestButtonElementOptions | undefined => {
  const theme = useTheme()
  return React.useMemo<StripePaymentRequestButtonElementOptions | undefined>(() => {
    if (paymentRequest) {
      return {
        paymentRequest,
        style: {
          paymentRequestButton: {
            type: type,
            theme: theme.palette.mode === 'light' ? 'dark' : 'light',
            height: '36px',
          },
        },
        classes: { base: 'payment-request-button' },
      }
    } else {
      return undefined
    }
  }, [paymentRequest, theme.palette.mode])
}
