import { Box, styled } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React from 'react'

type CenterTextType = {
  color?: string
  contrastColor?: string
}

export const CenterText: React.FC<CenterTextType> = ({ children, color, contrastColor }) => {
  const theme = useTheme()

  return (
    <StyledBox
      justifyContent={'center'}
      alignItems={'center'}
      textAlign={'center'}
      p={5}
      contrastColor={contrastColor || theme.palette.background.contrast}
      color={color || theme.palette.background.default}>
      {children}
    </StyledBox>
  )
}

const StyledBox = styled(Box)<CenterTextType>(({ contrastColor, color }) => ({
  width: '100%',
  backgroundColor: contrastColor,
  color: color,
}))
