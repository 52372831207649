import { Icon as Iconify, IconifyIcon } from '@iconify/react'
import { Box, BoxProps, SxProps } from '@mui/material'
import React from 'react'

interface IconProps extends BoxProps {
  sx?: SxProps
  icon: IconifyIcon | string
}

export const Icon: React.FC<IconProps> = ({ icon, sx, ...other }) => {
  return <Box component={Iconify} icon={icon} sx={{ ...sx }} {...other} />
}
