import { InputBaseComponentProps, TextField, TextFieldProps } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { CardCvcElementComponent, CardElementComponent, CardExpiryElementComponent, CardNumberElementComponent, IbanElementComponent } from '@stripe/react-stripe-js'
import { StripeElementChangeEvent } from '@stripe/stripe-js'
import React from 'react'

export type StripeElementInputState = 'empty' | 'error' | 'complete'

type StripeElementInputProps = TextFieldProps & {
  component:
    | {
        type: 'card'
        component: CardElementComponent | CardNumberElementComponent | CardExpiryElementComponent | CardCvcElementComponent
      }
    | {
        type: 'sepa'
        component: IbanElementComponent
      }
  onStateChange: (state: StripeElementInputState) => void
}

export const StripeElementInput: React.FC<StripeElementInputProps> = ({ component, onStateChange, ...props }) => {
  const [focussed, setFocussed] = React.useState<boolean>(false)
  const [empty, setEmpty] = React.useState<boolean>(true)
  const [error, setError] = React.useState<string>()

  return (
    <TextField
      {...props}
      sx={{
        ...props.sx,
        '.StripeElement': {
          position: 'relative',
          top: '3px',
        },
      }}
      InputLabelProps={{
        focused: focussed,
        shrink: focussed || !empty,
        error: !!error,
      }}
      InputProps={{
        inputComponent: InputComponentOverride as any,
        inputProps: {
          component,
          shrink: focussed || !empty,
          onFocus: () => setFocussed(true),
          onBlur: () => setFocussed(false),
          onStripeChange: (event: StripeElementChangeEvent) => {
            setEmpty(event.empty)
            setError(event.error?.message)
            onStateChange(event.complete ? 'complete' : event.empty ? 'empty' : 'error')
          },
        },
      }}
      error={!!error}
      helperText={error}
    />
  )
}

type InputComponentOverrideProps = InputBaseComponentProps &
  Pick<StripeElementInputProps, 'component'> & {
    shrink: boolean
    onStripeChange: (event: StripeElementChangeEvent) => void
  }

const InputComponentOverride = React.forwardRef<HTMLInputElement, InputComponentOverrideProps>(
  ({ component, shrink, onFocus, onBlur, onChange, onStripeChange, ...props }, _ref) => {
    const theme = useTheme()

    if (component.type === 'card') {
      const { component: Component } = component
      return (
        <Component
          onFocus={() => onFocus?.(null as any)}
          onBlur={() => onBlur?.(null as any)}
          onChange={event => {
            onStripeChange(event)
          }}
          options={{
            style: {
              base: {
                fontFamily: theme.typography.fontFamily,
                fontSize: '16px',
                fontWeight: theme.typography.fontWeightRegular,
                color: theme.palette.text.primary,
                fontSmoothing: 'antialiased',
                '::placeholder': {
                  color: shrink ? theme.palette.text.disabled : 'transparent',
                  fontSmoothing: 'antialiased',
                },
              },
            },
            hideIcon: !shrink,
            hidePostalCode: true,
          }}
          {...props}
        />
      )
    } else {
      const { component: Component } = component
      return (
        <Component
          onFocus={() => onFocus?.(null as any)}
          onBlur={() => onBlur?.(null as any)}
          onChange={event => {
            onStripeChange(event)
          }}
          options={{
            style: {
              base: {
                fontFamily: theme.typography.fontFamily,
                fontSize: '16px',
                fontWeight: theme.typography.fontWeightRegular,
                color: theme.palette.text.primary,
                fontSmoothing: 'antialiased',
                '::placeholder': {
                  color: shrink ? theme.palette.text.disabled : 'transparent',
                  fontSmoothing: 'antialiased',
                },
              },
            },
            supportedCountries: ['SEPA'],
            hideIcon: !shrink,
          }}
          {...props}
        />
      )
    }
  }
)

/*const InputComponentOverride1: React.FC<InputComponentOverrideProps> = ({ component: Component, onFocus, onBlur, onChange, onStripeChange, ...props }) => {
  const theme = useTheme()
  return (
    <Component
      onFocus={() => onFocus?.(null as any)}
      onBlur={() => onBlur?.(null as any)}
      onChange={event => {
        onStripeChange(event)
      }}
      options={{
        style: {
          base: {
            fontFamily: theme.typography.fontFamily,
            fontSize: '16px',
            fontWeight: theme.typography.fontWeightRegular,
            color: theme.palette.text.primary,
            '::placeholder': {
              color: theme.palette.text.disabled,
            },
          },
        },
        placeholder: '',
        supportedCountries: ['SEPA'],
      }}
      {...props}
    />
  )
}
*/
