import { Box, BoxProps, styled } from '@mui/material'
import React from 'react'

type BannerVideoProps = BoxProps & {
  src: string
}

export const BannerVideo: React.FC<BannerVideoProps> = ({ src, ...rest }) => {
  const [loaded, setLoaded] = React.useState(false)

  React.useEffect(() => {
    if (document.readyState !== 'complete') {
      const listener = () => {
        setTimeout(() => setLoaded(true), 300)
        window.removeEventListener('load', listener)
      }
      window.addEventListener('load', listener)
    } else if (!loaded) {
      setTimeout(() => setLoaded(true), 300)
    }
  })

  return (
    <Box {...rest}>
      {loaded ? (
        <Video autoPlay={true} muted={true} loop={true} playsInline={true} preload={'none'}>
          <source src={src} type={'video/mp4'} />
        </Video>
      ) : (
        <Placeholder />
      )}
    </Box>
  )
}

const Video = styled('video')(({ theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  backgroundColor: theme.palette.background.contrast,
}))

const Placeholder = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.background.contrast,
}))
