import { Beachchairs } from '@b4online/api-common'
import { NumberTuple } from '@b4online/uikit'

export const calculateCenter = (beachchairs: Pick<Beachchairs.Beachchair, 'location'>[]): NumberTuple => {
  if (beachchairs.length === 0) return [54.417644, 10.265549]
  return [
    beachchairs.reduce<number>((previousValue, currentValue) => currentValue.location.lat + previousValue, 0) / beachchairs.length,
    beachchairs.reduce<number>((previousValue, currentValue) => currentValue.location.long + previousValue, 0) / beachchairs.length,
  ]
}

export const calculateRegion = (
  beachchairs: Pick<Beachchairs.Beachchair, 'location'>[]
): {
  latitude: number
  longitude: number
  latitudeSpan: number
  longitudeSpan: number
} => {
  const center = calculateCenter(beachchairs)
  return {
    latitude: center[0],
    longitude: center[1],
    latitudeSpan: Math.max(
      (Math.max(...beachchairs.map(chair => chair.location.lat)) - Math.min(...beachchairs.map(chair => chair.location.lat))) * 1.5,
      beachchairs.length > 0 ? 0.005 : 0.5
    ),
    longitudeSpan: Math.max(
      (Math.max(...beachchairs.map(chair => chair.location.long)) - Math.min(...beachchairs.map(chair => chair.location.long))) * 1.5,
      beachchairs.length > 0 ? 0.005 : 0.5
    ),
  }
}
