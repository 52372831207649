import { Theme } from '@mui/material/styles'

export function Link(_theme: Theme) {
  return {
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
  }
}
