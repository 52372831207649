import { alpha, styled } from '@mui/material'
import { keyframes } from '@mui/styled-engine'
import React from 'react'

export const SuccessAnimation: React.FC = () => {
  return (
    <StyledCheckmark>
      <StyledCheckIcon>
        <StyledIconLineTip />
        <StyledIconLineLong />
        <StyledIconCircle />
        <StyledIconFix />
      </StyledCheckIcon>
    </StyledCheckmark>
  )
}

const rotateCircle = keyframes`
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
`

const iconLineTip = keyframes`
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
`

const iconLineLong = keyframes`
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
`

const StyledCheckmark = styled('div')(() => ({
  width: 88,
  height: 88,
}))

const StyledCheckIcon = styled('div')(({ theme }) => ({
  width: 80,
  height: 80,
  position: 'relative',
  borderRadius: '50%',
  boxSizing: 'content-box',
  border: `4px solid ${theme.palette.success.main}`,
  '&::before': {
    top: 3,
    left: -2,
    width: 30,
    transformOrigin: '100% 50%',
    borderRadius: '100px 0 0 100px',
  },
  '&::after': {
    top: 0,
    left: 30,
    width: 60,
    transformOrigin: '0 50%',
    borderRadius: '0 100px 100px 0',
    animation: `${rotateCircle} 4.25s ease-in`,
  },
  '&::before, &::after': {
    content: '""',
    height: 100,
    position: 'absolute',
    background: '#FFFFFF',
    transform: 'rotate(-45deg)',
  },
}))

const StyledIconLineTip = styled('span')(({ theme }) => ({
  height: 5,
  backgroundColor: theme.palette.success.main,
  display: 'block',
  borderRadius: 2,
  position: 'absolute',
  zIndex: 10,
  top: 46,
  left: 14,
  width: 25,
  transform: 'rotate(45deg)',
  animation: `${iconLineTip} 0.75s`,
}))

const StyledIconLineLong = styled('span')(({ theme }) => ({
  height: 5,
  backgroundColor: theme.palette.success.main,
  display: 'block',
  borderRadius: 2,
  position: 'absolute',
  zIndex: 10,
  top: 38,
  right: 8,
  width: 47,
  transform: 'rotate(-45deg)',
  animation: `${iconLineLong} 0.75s`,
}))

const StyledIconCircle = styled('div')(({ theme }) => ({
  top: -4,
  left: -4,
  zIndex: 10,
  width: 80,
  height: 80,
  borderRadius: '50%',
  position: 'absolute',
  boxSizing: 'content-box',
  border: `4px solid ${alpha(theme.palette.success.main, 0.5)}`,
}))

const StyledIconFix = styled('div')(() => ({
  top: 8,
  width: 5,
  left: 26,
  zIndex: 1,
  height: 85,
  position: 'absolute',
  transform: 'rotate(-45deg)',
  backgroundColor: '#FFFFFF',
}))
