import { createBeachchairsApi, createCoursesApi, createFaqApi, createFormsApi, createMapsApi, createNewsApi, createShopApi } from '@b4online/api'

export const customer = process.env.NEXT_PUBLIC_CUSTOMER ?? 'b4online'

export const api = {
  beachchairs: createBeachchairsApi({ customer: customer }),
  courses: createCoursesApi({ customer: customer }),
  faq: createFaqApi({ customer: customer }),
  forms: createFormsApi({ customer: customer }),
  maps: createMapsApi({ customer: customer }),
  shop: createShopApi({ customer: customer }),
  news: createNewsApi({ customer: customer }),
}
