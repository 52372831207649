import { Box, Stack, styled } from '@mui/material'
import React from 'react'

interface SponsorBarProps {
  backgroundColor?: string
}

export const SponsorBar: React.FC<SponsorBarProps> = ({ backgroundColor }) => {
  return (
    <StyledBox sx={{ backgroundColor }}>
      <Stack direction={'row'} justifyContent={{ xs: 'space-between', md: 'space-evenly' }} alignItems={'center'} sx={{ height: 1 }} gap={1} px={{ xs: 3, sm: 4 }}>
        <StyledImage src={'/static/images/partner/duotone.svg'} alt={'Duotone'} />
        <StyledImage src={'/static/images/partner/packeis.svg'} alt={'Packeis'} />
        <StyledImage src={'/static/images/partner/surfshop-kiel.svg'} alt={'Surfshop Kiel'} />
        <StyledImage src={'/static/images/partner/fritz-kola.svg'} alt={'Fritz-Kola'} sx={{ height: '40%' }} />
        <StyledImage src={'/static/images/partner/faedd.svg'} alt={'Faedd'} />
        <StyledImage src={'/static/images/partner/lille.svg'} alt={'Lille'} sx={{ height: '40%' }} />
      </Stack>
    </StyledBox>
  )
}

const StyledBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '80px',
  backgroundColor: theme.palette.background.contrast,
  boxShadow: theme.shadows['5'],
  overflowX: 'auto',
}))

const StyledImage = styled('img')(({ theme }) => ({
  display: 'block',
  height: '50%',
  [theme.breakpoints.down('sm')]: {
    height: '30%',
  },
}))
